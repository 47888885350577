window.Rails = require("@rails/ujs")
Rails.start()

require("@fancyapps/fancybox")

import '../../lib/common'
import 'lib/scroll-header'
import '../components/notice_email_confirmation'
import 'bootstrap'
import 'components/picture_lazy_loading'

require('lib/event_countdown')

$(function() {
  var navbarTogglerElement = document.getElementById('content').querySelector('.navbar-toggler'),
    overlayElement = $(document.getElementById('overlay'));

  $(navbarTogglerElement).on('click', function() {
    toggleOverlay(200);
  });

  overlayElement.on('click', function() {
    $(navbarTogglerElement).trigger('click');
  });

  /* Functions */
  function toggleOverlay(time) {
    overlayElement.fadeToggle(time, function() {
      overlayElement.toggleClass('active');
    });
  }
});

if (window.location.href.indexOf('admin') === -1) {
  window.addEventListener('load', () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/xnxx-service-worker.js');
    }
  });
}
